import React from "react";
import PageTitle from "../../components/pagetitle/PageTitle";
import Footer from "../../components/footer/Footer";
import Sidebar from "../../components/sidebar/Sidebar";
import { Link } from "react-router-dom";

import img1 from "../../assets/images/new/security (2).webp";
import avt1 from "../../assets/images/layouts/avt-01.png";
import avt2 from "../../assets/images/layouts/avt-02.png";
import avt3 from "../../assets/images/layouts/avt-03.png";
import avt4 from "../../assets/images/layouts/avt-04.png";
import Footer2 from "../../components/footer/Footer2";

function Security(props) {
  return (
    <div className="wrapper">
      <section className="page-title">
        <div className="shape"></div>
        <div className="shape right s3"></div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="page-title__body">
                <div className="page-title__main">
                  <h4 style={{ fontSize: "20px" }} className="title">
                    {"Understanding Cyber Security: A Comprehensive Guide"}
                  </h4>

                  {/* <ul className="breacrumb">
                                <li><a href="/">Home</a></li>
                                <li><p>Explore</p></li>
                                <li><p>{title}</p></li>
                            </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bloglist">
        <div className="container">
          <div className="row">
            <div className="bloglist__main">
              <div className="list details">
                <p>
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.9375 0.75V2.0625M9.0625 0.75V2.0625M0.75 9.9375V3.375C0.75 3.0269 0.888281 2.69306 1.13442 2.44692C1.38056 2.20078 1.7144 2.0625 2.0625 2.0625H9.9375C10.2856 2.0625 10.6194 2.20078 10.8656 2.44692C11.1117 2.69306 11.25 3.0269 11.25 3.375V9.9375M0.75 9.9375C0.75 10.2856 0.888281 10.6194 1.13442 10.8656C1.38056 11.1117 1.7144 11.25 2.0625 11.25H9.9375C10.2856 11.25 10.6194 11.1117 10.8656 10.8656C11.1117 10.6194 11.25 10.2856 11.25 9.9375M0.75 9.9375V5.5625C0.75 5.2144 0.888281 4.88056 1.13442 4.63442C1.38056 4.38828 1.7144 4.25 2.0625 4.25H9.9375C10.2856 4.25 10.6194 4.38828 10.8656 4.63442C11.1117 4.88056 11.25 5.2144 11.25 5.5625V9.9375"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  February 11, 2023
                </p>

                <div className="line"></div>

                <div className="content-d">
                  {/* <ul className="list-social">
                    <li>
                      <Link to="#">
                        <svg
                          width="9"
                          height="16"
                          viewBox="0 0 9 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.57969 9.03953C2.51969 9.03953 1.19969 9.03953 0.599688 9.03953C0.279688 9.03953 0.179688 8.91953 0.179688 8.61953C0.179688 7.81953 0.179688 6.99953 0.179688 6.19953C0.179688 5.87953 0.299688 5.77953 0.599688 5.77953H2.57969C2.57969 5.71953 2.57969 4.55953 2.57969 4.01953C2.57969 3.21953 2.71969 2.45953 3.11969 1.75953C3.53969 1.03953 4.13969 0.559531 4.89969 0.279531C5.39969 0.0995311 5.89969 0.0195312 6.43969 0.0195312H8.39969C8.67969 0.0195312 8.79969 0.139531 8.79969 0.419531V2.69953C8.79969 2.97953 8.67969 3.09953 8.39969 3.09953C7.85969 3.09953 7.31969 3.09953 6.77969 3.11953C6.23969 3.11953 5.95969 3.37953 5.95969 3.93953C5.93969 4.53953 5.95969 5.11953 5.95969 5.73953H8.27969C8.59969 5.73953 8.71969 5.85953 8.71969 6.17953V8.59953C8.71969 8.91953 8.61969 9.01953 8.27969 9.01953C7.55969 9.01953 6.01969 9.01953 5.95969 9.01953V15.5395C5.95969 15.8795 5.85969 15.9995 5.49969 15.9995C4.65969 15.9995 3.83969 15.9995 2.99969 15.9995C2.69969 15.9995 2.57969 15.8795 2.57969 15.5795C2.57969 13.4795 2.57969 9.09953 2.57969 9.03953Z"
                            fill="white"
                          ></path>
                        </svg>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <svg
                          width="15"
                          height="12"
                          viewBox="0 0 15 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.5 1.42062C13.9794 1.66154 13.4246 1.82123 12.8462 1.89877C13.4412 1.524 13.8954 0.935077 14.1089 0.225231C13.5541 0.574154 12.9416 0.820615 12.2889 0.958154C11.7621 0.366462 11.0114 0 10.1924 0C8.60337 0 7.32412 1.36062 7.32412 3.02862C7.32412 3.26862 7.34338 3.49938 7.39062 3.71908C5.0045 3.59631 2.89313 2.38985 1.47475 0.552C1.22712 1.00523 1.08188 1.524 1.08188 2.08246C1.08188 3.13108 1.59375 4.06062 2.35675 4.59877C1.89562 4.58954 1.44325 4.44831 1.06 4.22585C1.06 4.23508 1.06 4.24708 1.06 4.25908C1.06 5.73046 2.05487 6.95262 3.3595 7.23415C3.12587 7.30154 2.87125 7.33385 2.607 7.33385C2.42325 7.33385 2.23775 7.32277 2.06362 7.28215C2.4355 8.48123 3.49075 9.36277 4.7455 9.39138C3.769 10.1972 2.52912 10.6828 1.18688 10.6828C0.9515 10.6828 0.72575 10.6717 0.5 10.6412C1.77137 11.5062 3.27813 12 4.903 12C10.1845 12 13.072 7.38462 13.072 3.384C13.072 3.25015 13.0676 3.12092 13.0615 2.99262C13.6311 2.56615 14.1097 2.03354 14.5 1.42062Z"
                            fill="white"
                          ></path>
                        </svg>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.0006 14V8.87249C14.0006 6.35249 13.4581 4.42749 10.5181 4.42749C9.10062 4.42749 8.15563 5.19749 7.77063 5.93249H7.73563V4.65499H4.95312V14H7.85813V9.36249C7.85813 8.13749 8.08563 6.96499 9.59063 6.96499C11.0781 6.96499 11.0956 8.34749 11.0956 9.43249V13.9825H14.0006V14Z"
                            fill="white"
                          ></path>
                          <path
                            d="M0.226562 4.65479H3.13156V13.9998H0.226562V4.65479Z"
                            fill="white"
                          ></path>
                          <path
                            d="M1.68 0C0.7525 0 0 0.7525 0 1.68C0 2.6075 0.7525 3.3775 1.68 3.3775C2.6075 3.3775 3.36 2.6075 3.36 1.68C3.36 0.7525 2.6075 0 1.68 0Z"
                            fill="white"
                          ></path>
                        </svg>
                      </Link>
                    </li>
                  </ul> */}

                  <div className="main">
                    <p style={{ fontSize: '20px' }} className="boild">
                      In today’s digital age, cyber security is a critical aspect of protecting information and systems from digital attacks. These attacks aim to access, change, or destroy sensitive information, extort money from users, or interrupt normal business processes. Here's an in-depth look at the essential elements of cyber security.
                    </p>
                    <div className="image">
                      <img
                        style={{
                          backgroundColor: "#FFE09D",
                          width: "100%",
                          height: "100%",
                        }}
                        src={img1}
                        alt="cyfonii"
                      />
                      <p>
                        Cyber security image
                      </p>
                    </div>
                    <p style={{ fontSize: '18px' }} className="mb-15">
                      1. What is Cyber Security?
                      Cyber security is the practice of defending computers, servers, mobile devices, electronic systems, networks, and data from malicious attacks. It’s also known as information technology security or electronic information security. The term applies in a variety of contexts, from business to mobile computing, and can be divided into a few common categories.
                      <br />  <br />
                      2. Types of Cyber Security
                      Network Security: Protects the integrity, confidentiality, and accessibility of networks and data using both hardware and software technologies.
                      Application Security: Focuses on keeping software and devices free of threats. A compromised application can provide access to the data it’s designed to protect.
                      Information Security: Protects the integrity and privacy of data, both in storage and in transit.
                      Operational Security: Includes the processes and decisions for handling and protecting data assets. This encompasses the permissions users have when accessing a network and the procedures that determine how and where data may be stored or shared.
                      End-User Education: Addresses the most unpredictable cyber security factor: people. Anyone can accidentally introduce a virus to an otherwise secure system by failing to follow good security practices.
                      Disaster Recovery and Business Continuity: Defines how an organization responds to a cyber-security incident or any other event that causes the loss of operations or data.
                      <br />  <br /> 3. Common Cyber Threats
                      Malware: Malicious software including viruses, worms, Trojans, ransomware, spyware, and adware.
                      Phishing: Fraudulent attempts to obtain sensitive information by disguising oneself as a trustworthy entity.
                      Man-in-the-Middle Attack: Occurs when attackers insert themselves into a two-party transaction. After interrupting the traffic, they can filter and steal data.
                      Denial-of-Service Attack: Floods systems, servers, or networks with traffic to exhaust resources and bandwidth. As a result, the system is unable to fulfill legitimate requests.
                      SQL Injection: Occurs when an attacker inserts malicious code into a server that uses SQL and forces the server to reveal information it normally wouldn’t.
                      Zero-Day Exploit: A vulnerability that is exploited before the software creator has a chance to create a fix.
                      <br />   <br />  4. Best Practices for Cyber Security
                      Regular Software Updates: Ensuring software, including anti-virus software, is up to date can help protect systems from vulnerabilities.
                      Strong Passwords and Authentication: Use complex passwords and enable multi-factor authentication to add an additional layer of security.
                      Employee Training: Educate employees about common cyber threats and safe practices.
                      Data Encryption: Encrypt sensitive data to protect it from unauthorized access.
                      Firewalls and Anti-Virus Software: Install and maintain firewalls and anti-virus software to protect against malware.
                      Regular Backups: Back up data regularly to ensure that you can restore it in case of data loss.
                      Incident Response Plan: Develop and implement a plan to respond to cyber security incidents.
                      <br />   <br />  5. The Future of Cyber Security
                      As technology evolves, so do the threats to cyber security. Future trends may include:

                      Artificial Intelligence and Machine Learning: These technologies can help detect anomalies and predict potential threats.
                      IoT Security: As more devices connect to the internet, the need for robust IoT security increases.
                      Quantum Computing: Quantum computing poses both opportunities and threats for cyber security.
                      Cloud Security: With the increasing adoption of cloud services, ensuring robust cloud security measures will be critical.
                      <br /> <br /> Conclusion :
                      Cyber security is an ever-evolving field crucial for protecting sensitive information and maintaining the integrity of digital infrastructures. By understanding the types of threats and implementing best practices, individuals and organizations can better safeguard themselves against cyber attacks.
                    </p>


                    <div className="line"></div>

                    <div className="bottom">
                      <p>Tags:</p>
                      <ul className="list-tag">
                        <li>
                          <Link to="/blog-details">Ai</Link>
                        </li>
                        <li>
                          <Link to="/blog-details">Web3</Link>
                        </li>
                        <li>
                          <Link to="/blog-details">Fintech</Link>
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>

                <div className="bottom">
                  <div className="recent">
                    <div className="ac prev">
                      <svg
                        width="20"
                        height="36"
                        viewBox="0 0 10 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.75 16.5L1.25 9L8.75 1.5"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>

                      <div>
                        <div className="meta ">
                          <Link to="/blog/ai-startup">
                            <svg
                              width="12"
                              height="12"
                              viewBox="0 0 12 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.9375 0.75V2.0625M9.0625 0.75V2.0625M0.75 9.9375V3.375C0.75 3.0269 0.888281 2.69306 1.13442 2.44692C1.38056 2.20078 1.7144 2.0625 2.0625 2.0625H9.9375C10.2856 2.0625 10.6194 2.20078 10.8656 2.44692C11.1117 2.69306 11.25 3.0269 11.25 3.375V9.9375M0.75 9.9375C0.75 10.2856 0.888281 10.6194 1.13442 10.8656C1.38056 11.1117 1.7144 11.25 2.0625 11.25H9.9375C10.2856 11.25 10.6194 11.1117 10.8656 10.8656C11.1117 10.6194 11.25 10.2856 11.25 9.9375M0.75 9.9375V5.5625C0.75 5.2144 0.888281 4.88056 1.13442 4.63442C1.38056 4.38828 1.7144 4.25 2.0625 4.25H9.9375C10.2856 4.25 10.6194 4.38828 10.8656 4.63442C11.1117 4.88056 11.25 5.2144 11.25 5.5625V9.9375"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            April 11, 2024
                          </Link>
                        </div>
                        <Link to="/blog/ai-startup" className="title">
                          Start An Ai Start-up
                        </Link>
                      </div>
                    </div>
                    <div className="ac next">
                      <svg
                        width="20"
                        height="36"
                        viewBox="0 0 10 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.25 16.5L8.75 9L1.25 1.5"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>

                      <div>
                        <div className="meta ">
                          <Link to="/blog/fintech">
                            <svg
                              width="12"
                              height="12"
                              viewBox="0 0 12 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.9375 0.75V2.0625M9.0625 0.75V2.0625M0.75 9.9375V3.375C0.75 3.0269 0.888281 2.69306 1.13442 2.44692C1.38056 2.20078 1.7144 2.0625 2.0625 2.0625H9.9375C10.2856 2.0625 10.6194 2.20078 10.8656 2.44692C11.1117 2.69306 11.25 3.0269 11.25 3.375V9.9375M0.75 9.9375C0.75 10.2856 0.888281 10.6194 1.13442 10.8656C1.38056 11.1117 1.7144 11.25 2.0625 11.25H9.9375C10.2856 11.25 10.6194 11.1117 10.8656 10.8656C11.1117 10.6194 11.25 10.2856 11.25 9.9375M0.75 9.9375V5.5625C0.75 5.2144 0.888281 4.88056 1.13442 4.63442C1.38056 4.38828 1.7144 4.25 2.0625 4.25H9.9375C10.2856 4.25 10.6194 4.38828 10.8656 4.63442C11.1117 4.88056 11.25 5.2144 11.25 5.5625V9.9375"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            June 18, 2024
                          </Link>
                        </div>
                        <Link to="/blog/fintech" className="title">
                          Guide to Modern Fintech Software Development
                        </Link>
                      </div>
                    </div>
                  </div>

                  {/* <div className="comment">
                    <h5>Comment (4)</h5>

                    <div className="comment-box s1">
                      <div className="avt">
                        <img src={avt1} alt="cyfonii" />
                      </div>
                      <div className="info">
                        <h6 className="name">Annette Black</h6>
                        <p className="meta">February 18, 2022</p>
                        <p className="text">
                          Nullam ornare a magna quis aliquet. Duis suscipit eros
                          in suscipit venenatis. Pellentesque quis efficitur
                          leo. Maecenas accumsan est in nibh interdum, quis
                          dignissim neque scelerisque.
                        </p>
                        <Link to="#">Reply</Link>
                      </div>
                    </div>
                    <div className="comment-box rep">
                      <div className="avt">
                        <img src={avt2} alt="cyfonii" />
                      </div>
                      <div className="info">
                        <h6 className="name">Darlene Robertson</h6>
                        <p className="meta">February 18, 2022</p>
                        <p className="text">
                          Nullam ornare a magna quis aliquet. Duis suscipit eros
                          in suscipit venenatis. Pellentesque quis efficitur
                          leo. Maecenas accumsan est in nibh interdum, quis
                          dignissim neque scelerisque.
                        </p>
                        <Link to="#">Reply</Link>
                      </div>
                    </div>
                    <div className="comment-box">
                      <div className="avt">
                        <img src={avt3} alt="cyfonii" />
                      </div>
                      <div className="info">
                        <h6 className="name">Ronald Richards</h6>
                        <p className="meta">February 18, 2022</p>
                        <p className="text">
                          Nullam ornare a magna quis aliquet. Duis suscipit eros
                          in suscipit venenatis. Pellentesque quis efficitur
                          leo. Maecenas accumsan est in nibh interdum, quis
                          dignissim neque scelerisque.
                        </p>
                        <Link to="#">Reply</Link>
                      </div>
                    </div>
                    <div className="comment-box">
                      <div className="avt">
                        <img src={avt4} alt="cyfonii" />
                      </div>
                      <div className="info">
                        <h6 className="name">Theresa Webb</h6>
                        <p className="meta">February 18, 2022</p>
                        <p className="text">
                          Nullam ornare a magna quis aliquet. Duis suscipit eros
                          in suscipit venenatis. Pellentesque quis efficitur
                          leo. Maecenas accumsan est in nibh interdum, quis
                          dignissim neque scelerisque.
                        </p>
                        <Link to="#">Reply</Link>
                      </div>
                    </div>
                  </div>

                  <form action="#" className="form-box">
                    <div className="row">
                      <div className="col">
                        <h5 className="mb-14">Leave your thought here</h5>
                        <p>
                          Your email address will not be published. Required
                          fields are marked *
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <label>Your name</label>
                        <input type="text" className="form-control" />
                      </div>
                      <div className="col">
                        <label>Your email</label>
                        <input type="email" className="form-control" />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <label>Your comment</label>
                        <textarea
                          name="comment"
                          id="comment"
                          cols="30"
                          rows="10"
                        ></textarea>
                      </div>
                    </div>
                    <div className="row mb-0">
                      <div className="col">
                        <button className="action-btn">
                          <span>Submit</span>
                        </button>
                      </div>
                    </div>
                  </form> */}
                </div>
              </div>

              <Sidebar />
            </div>
          </div>
        </div>
      </section>

      <Footer2 />
    </div>
  );
}

export default Security;
