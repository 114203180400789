import React from "react";

import PageTitle from "../components/pagetitle/PageTitle";
import Footer from "../components/footer/Footer";

import Team2 from "../components/team/Team2";
import dataTeam from "../assets/fake-data/dataTeam";
import Create from "../components/create/Create";
import Footer2 from "../components/footer/Footer2";

function Team(props) {
  return (
    <div className="page-team wrapper">
      <PageTitle title="Our Team" />

      <Team2 data={dataTeam} />

      <Create />

      <Footer2 />
    </div>
  );
}

export default Team;
