import React from "react";
import Project3 from "../components/project/Project3";
import dataItem from "../assets/fake-data/data-item";
import PageTitle from "../components/pagetitle/PageTitle";
import Footer2 from "../components/footer/Footer2";
import Project4 from "../components/project/Project4";

export default function Project() {
  return (
    <div className="wrapper">
      <PageTitle title="SOME OF OUR RECENT PROJECTS INTERNATIONALLY" />
      <Project4 data={dataItem} />
      <Footer2 />
    </div>
  );
}
