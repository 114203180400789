import React, { useState } from "react";

import Button from "../button/Button";
import Partner from "../partner/Partner";

import img from "../../assets/images/new/soft.webp";

function About2(props) {
  const [dataBlock] = useState({
    subheading: "About us",
    heading: "Next Generation Software Solutions",

    desc1:
      "GenSoft, a pioneering software firm dedicated to delivering innovative technology solutions that drive success and transform industries. ",
    desc2:
      "We have grown into a trusted partner for businesses of all sizes, offering a diverse portfolio of products and services designed to meet the unique challenges of the modern digital landscape.",
  });
  return (
    <section className="about s2">
      <div className="shape"></div>
      <div className="shape right"></div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="block-text center pd-0">
              <h6 className="sub-heading">
                <span>{dataBlock.subheading}</span>
              </h6>
              <h4 className="heading pd">{dataBlock.heading}</h4>
            </div>

            <div
              className="about__main center"
              data-aos="fade-up"
              data-aos-duration="3000"
            >
              <img
                //   style={{ width: "30vw" }}
                src={img}
                alt="gensoft"
              />
              <p style={{ fontSize: "20px" }} className="mb-10">
                {dataBlock.desc1}
              </p>

              <Button title="More About Us" link="/about" />
            </div>

            <Partner />
          </div>
        </div>
      </div>
    </section>
  );
}

export default About2;
