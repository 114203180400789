
import img1 from '../images/layouts/team-01.png'
import img2 from '../images/layouts/team-02.png'
import img3 from '../images/layouts/team-03.png'
import img4 from '../images/layouts/team-04.png'
import img5 from '../images/layouts/team-05.png'
import img6 from '../images/layouts/team-06.png'
import img7 from '../images/layouts/team-07.png'
import img8 from '../images/layouts/team-08.png'

const dataTeam = [
    {
        id: 1,
        title: "Md Joynul Abedin Pavel",
        cover: "https://www.nj.com/resizer/zovGSasCaR41h_yUGYHXbVTQW2A=/1280x0/smart/cloudfront-us-east-1.images.arcpublishing.com/advancelocal/SJGKVE5UNVESVCW7BBOHKQCZVE.jpg",
        post: "FOUNDER, CEO",
    },
    {
        id: 22,
        title: "Rahim Hossain",
        cover: "https://dt2sdf0db8zob.cloudfront.net/wp-content/uploads/2019/12/9-Best-Online-Avatars-and-How-to-Make-Your-Own-for-Free-image1-5.png",
        post: "Lead Data Scientist",
    },
    {
        id: 33,
        title: "Khalid Hossain",
        cover: "https://i.kinja-img.com/gawker-media/image/upload/t_original/ijsi5fzb1nbkbhxa2gc1.png",
        post: "Lead Developer",
    },
    {
        id: 5509,
        title: "Foysal Ahmad",
        cover: "https://static.toiimg.com/thumb/resizemode-4,msid-76729750,imgsize-249247,width-720/76729750.jpg",
        post: "Software Developer",
    },
    {
        id: 334323,
        title: "M A Momin",
        cover: "https://i.kinja-img.com/gawker-media/image/upload/t_original/ijsi5fzb1nbkbhxa2gc1.png",
        post: "Software Developer",
    },
    {
        id: 44,
        title: "Fuzael Ibn Elius",
        cover: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOTdXtCSZF9KcRWvBcQFEAU8L1b1aSVh0MkOfmDppXrcdcnGsuE83fYb51VsUN9Epx3y8&usqp=CAU",
        post: "Analyst",
    },
    {
        id: 55,
        title: "Muhammad",
        cover: "https://pbs.twimg.com/media/EYVxlOSXsAExOpX.jpg",
        post: "Software Developer",
    },
    {
        id: 555,
        title: "Mahmudur Rahman",
        cover: "https://static.toiimg.com/thumb/resizemode-4,msid-76729750,imgsize-249247,width-720/76729750.jpg",
        post: "Project Manager",
    },
    {
        id: 4214,
        title: "Fahim Rimon",
        cover: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTOTdXtCSZF9KcRWvBcQFEAU8L1b1aSVh0MkOfmDppXrcdcnGsuE83fYb51VsUN9Epx3y8&usqp=CAU",
        post: "Frontend Developer",
    },
    {
        id: 5235,
        title: "Shahalam Sharif",
        cover: "https://pbs.twimg.com/media/EYVxlOSXsAExOpX.jpg",
        post: " Full-stack Software Developer",
    },
    {
        id: 66,
        title: "Hossain Shahdat",
        cover: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQGncJl8idbwAqfO8_rhstd3gIYM1a7XPUqLA&usqp=CAU",
        post: "Marketing Executive",
    },



]



export default dataTeam;