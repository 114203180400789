
import img1 from '../images/icon/icon1.png'
import img2 from '../images/icon/icon2.png'
import img3 from '../images/icon/icon3.png'
import img4 from '../images/icon/icon4.png'



const dataBox = [

    {
        id: 1,
        img: img1,
        title: 'Web & Mobile App Development',
        price: '2.26 ETH',

        name: 'Leslie Alexander',
        tag: '@leslie754',
        bg: '#F58EFF'
    },

    {
        id: 2,
        img: img2,
        title: 'UI UX Design',
        price: '2.26 ETH',

        name: 'Leslie Alexander',
        tag: '@leslie754'
        ,
        bg: '#FF8E55'
    },
    {
        id: 3,
        img: img3,
        title: 'Automation',
        price: '2.26 ETH',

        name: 'Leslie Alexander',
        tag: '@leslie754'
        ,
        bg: '#9FFCFD '
    },
    {
        id: 4,
        img: img4,
        title: 'Artificial Intelligence (Ai)',
        price: '2.26 ETH',

        name: 'Leslie Alexander',
        tag: '@leslie754'
        ,
        bg: '#EF88BE'
    },
    {
        id: 5,
        img: img1,
        title: 'Web3/ Blockchain Development ',
        price: '2.26 ETH',

        name: 'Leslie Alexander',
        tag: '@leslie754'
        ,
        bg: '#8288FF'
    },
    {
        id: 5,
        img: img2,
        title: 'Cyber Security ',
        price: '2.26 ETH',

        name: 'Leslie Alexander',
        tag: '@leslie754'
        ,
        bg: '#BFFFC0'
    },
    {
        id: 5,
        img: img3,
        title: 'Robotics ',
        price: '2.26 ETH',

        name: 'Leslie Alexander',
        tag: '@leslie754'
        ,
        bg: '#FFE793'
    },


]

export default dataBox;