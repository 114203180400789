
import img1 from '../images/new/aws.webp'
import img2 from '../images/new/gcp.webp'
import img3 from '../images/new/meta.webp'
import img4 from '../images/new/chatgpt.webp'
import img5 from '../images/new/android.webp'
import img6 from '../images/new/ios.webp'


const dataPartner = [

    {
        id: 1,
        img: img1,
        title: 'AWS'
    },
    {
        id: 2,
        img: img2,
        title: 'GCP'
    },
    {
        id: 3,
        img: img3,
        title: 'Meta'
    },
    {
        id: 4,
        img: img4,
        title: 'OpenAi'
    },
    {
        id: 5,
        img: img5,
        title: 'Android'
    },
    {
        id: 6,
        img: img6,
        title: 'iOS'
    },


]

export default dataPartner;