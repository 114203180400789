
import img1 from '../images/new/fintech.webp'
import img2 from '../images/new/ai1.webp'
import img3 from '../images/new/security (2).webp'


const dataBlog = [

    {
        id: 1,
        img: img1,
        title: 'Guide to Modern Fintech Software Development',
        time: 'June 18, 2024',
        bg: '#ACEDFF',
        link: 'blog/fintech',
        text: 'Below is a list of frequently asked questions and answers from partners and 3D artist. Please check this FAQ first before contacting us.'
    },
    {
        id: 2,
        img: img2,
        title: 'Start An Ai Start-up',
        time: 'April 11, 2024',
        bg: '#FFB8FF',
        link: 'blog/ai-startup',

        text: 'Below is a list of frequently asked questions and answers from partners and 3D artist. Please check this FAQ first before contacting us.'
    },
    {
        id: 3,
        img: img3,
        title: 'Understanding Cyber Security: A Comprehensive Guide',
        time: 'February 11, 2023',
        bg: '#FFE09D',
        link: 'blog/cyber-security',
        text: 'Below is a list of frequently asked questions and answers from partners and 3D artist. Please check this FAQ first before contacting us.'
    },
    {
        id: 4,
        img: img1,
        title: '5 CELEBRITY-BACKED NFT PROJECTS THAT TURNED OUT TO BE SCAMS',
        bg: '#B700FF',
        text: 'Below is a list of frequently asked questions and answers from partners and 3D artist. Please check this FAQ first before contacting us.'
    },
    {
        id: 5,
        img: img2,
        title: 'DO NFT GAMES HAVE AN ADVANTAGE OVER TRADITIONAL GAMES?',
        time: 'February 18, 2022',
        text: 'Below is a list of frequently asked questions and answers from partners and 3D artist. Please check this FAQ first before contacting us.'
    },
    {
        id: 6,
        img: img3,
        title: 'Decentral Games Introduces ‘Sit-N-Go’ Tournaments In ICE Poker Flex App',
        time: 'February 18, 2022',
        text: 'Below is a list of frequently asked questions and answers from partners and 3D artist. Please check this FAQ first before contacting us.'
    },
    {
        id: 7,
        img: img1,
        title: '5 CELEBRITY-BACKED NFT PROJECTS THAT TURNED OUT TO BE SCAMS',
        time: 'February 18, 2022',
        text: 'Below is a list of frequently asked questions and answers from partners and 3D artist. Please check this FAQ first before contacting us.'
    },
    {
        id: 8,
        img: img2,
        title: 'DO NFT GAMES HAVE AN ADVANTAGE OVER TRADITIONAL GAMES?',
        time: 'February 18, 2022',
        text: 'Below is a list of frequently asked questions and answers from partners and 3D artist. Please check this FAQ first before contacting us.'
    },


]

export default dataBlog;