
import icon1 from '../images/icon/quote.png'
import avt1 from '../images/layouts/avt-02.png'


const dataTestimonials = [

    {
        id: 1,
        icon: icon1,
        text: '“ Curabitur eu est feugiat quam feugiat tristique non vel erat. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque congue, nunc nec suscipit fringilla, augue ligula eleifend velit. “',
        avt: avt1,
        name: 'Jacob Jones',
        position: 'Chief Product Officer'
    },
    {
        id: 2,
        icon: icon1,
        text: '“ Curabitur eu est feugiat quam feugiat tristique non vel erat. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque congue, nunc nec suscipit fringilla, augue ligula eleifend velit. “',
        avt: avt1,
        name: 'Jacob Jones',
        position: 'Chief Product Officer'
    },
    {
        id: 3,
        icon: icon1,
        text: '“ Curabitur eu est feugiat quam feugiat tristique non vel erat. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque congue, nunc nec suscipit fringilla, augue ligula eleifend velit. “',
        avt: avt1,
        name: 'Jacob Jones',
        position: 'Chief Product Officer'
    },

]

export default dataTestimonials;