import React from "react";
import Speciality from "../components/speciality/Speciality";
import dataBox from "../assets/fake-data/data-box";
import PageTitle from "../components/pagetitle/PageTitle";
import Faq3 from "../components/faqs/Faq3";
import Footer2 from "../components/footer/Footer2";
import dataFaqs from "../assets/fake-data/data-faq";

export default function Services() {
  return (
    <div className="wrapper">
      <PageTitle title="Services" />

      <Speciality data={dataBox} />
      {/* <Faq3 data={dataFaqs} /> */}

      <Footer2 />
    </div>
  );
}
