import React, { useState } from "react";
import PropTypes from "prop-types";

import { Autoplay, EffectCoverflow, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import { Link } from "react-router-dom";

Project.propTypes = {
  data: PropTypes.array,
};

function Project(props) {
  const { data } = props;

  const [dataBlock] = useState({
    subheading: "Projects Internationally",
    heading: "Some of Our Global Clients",
  });
  return (
    <section style={{ marginTop: "150px" }} className="project">
      <div className="shape right"></div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="block-text center">
              <h6 className="sub-heading">
                <span>{dataBlock.subheading}</span>
              </h6>
              <h3 className="heading">{dataBlock.heading}</h3>
            </div>

            <Swiper
              className="project-swiper"
              spaceBetween={20}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                991: {
                  slidesPerView: 4,
                },
              }}
              loop={true}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Pagination, Navigation, Autoplay]}
              navigation
              pagination={{
                clickable: true,
              }}
            >
              {data.map((idx) => (
                <SwiperSlide key={idx.id}>
                  <Link
                    to={idx?.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="swiper-slide">
                      <div className="project-box">
                        <div className="image">
                          <Link
                            to={idx?.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            key={idx.id}
                            className="col-xl-3 col-md-6"
                          >
                            <img
                              style={{ backgroundColor: "whitesmoke" }}
                              src={idx.img}
                              alt="Cyfonii"
                            />
                          </Link>
                        </div>
                        <div
                          style={{ backgroundColor: "#0000007c" }}
                          className="content"
                        >
                          <Link
                            to={idx?.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            key={idx.id}
                            className="h6 title"
                          >
                            {idx.title}
                          </Link>
                          <p
                            style={{ fontFamily: "arial" }}
                            className="postion"
                          >
                            {idx.des}
                          </p>
                        </div>
                      </div>
                    </div>
                  </Link>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Project;
