import React, { useState } from "react";

import PageTitle from "../components/pagetitle/PageTitle";
import About2 from "../components/about/About2";
import Counter from "../components/counter/Counter";
import Portfolio from "../components/portfolio/Portfolio";
import Team from "../components/team/Team";
import dataTeam from "../assets/fake-data/dataTeam";
import Footer from "../components/footer/Footer";
import dataPortfolio from "../assets/fake-data/data-portfolio";

import Button from "../components/button/Button";

import img from "../assets/images/new/team.webp";
import img2 from "../assets/images/new/soft.webp";
import Footer2 from "../components/footer/Footer2";
import Partner from "../components/partner/Partner";
import Team2 from "../components/team/Team2";
import VisionsMission from "./VisionsMission";

function About(props) {
  const [dataBlock] = useState({
    subheading: "About us",
    heading: "Next Generation Software Solutions ",

    desc1:
      "GenSoft, a pioneering software firm dedicated to delivering innovative technology solutions that drive success and transform industries. ",
    desc2:
      "We have grown into a trusted partner for businesses of all sizes, offering a diverse portfolio of products and services designed to meet the unique challenges of the modern digital landscape.",
  });
  return (
    <div className="page-team wrapper">
      <VisionsMission></VisionsMission>
      <div>
        <Portfolio data={dataPortfolio} />

        {/* <Team2 data={dataTeam} /> */}

        <section style={{ marginTop: "50px" }} className="create">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="create__main">
                  <div className="content">
                    <h4 className="heading">Create your systems with us</h4>
                    <p>Get udpated with news, tips & tricks</p>
                    <Button title="Join Now" link="/contact" />
                  </div>
                  <img src={img} alt="Cyfonii" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer2 />
      </div>
    </div>
  );
}

export default About;
