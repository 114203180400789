

const dataRoadMap = [

    {
        id: 1,
        time: '1st',
        title: 'Idea Generation',
        desc: `The planning phase typically includes tasks like cost-benefit analysis, scheduling, resource estimation, and allocation. The development team collects requirements from several stakeholders such as customers, internal and external experts, and managers to create a software requirement specification document.

The document sets expectations and defines common goals that aid in project planning. The team estimates costs, creates a schedule, and has a detailed plan to achieve their goals.`,
        class: ''
    },
    {
        id: 2,
        time: '2nd',
        title: 'Designing',
        desc: `In the design phase, software engineers analyze requirements and identify the best solutions to create the software. For example, they may consider integrating pre-existing modules, make technology choices, and identify development tools. They will look at how to best integrate the new software into any existing IT infrastructure the organization may have.`, class: 'right'
    },

    {
        id: 3,
        time: '3rd',
        title: 'Development',
        desc: `In the implementation phase, the development team codes the product. They analyze the requirements to identify smaller coding tasks they can do daily to achieve the final result.`, class: ''
    },

    {
        id: 4,
        time: '4th',
        title: 'Testing',
        desc: `The development team combines automation and manual testing to check the software for bugs. Quality analysis includes testing the software for errors and checking if it meets customer requirements. Because many teams immediately test the code they write, the testing phase often runs parallel to the development phase.`, class: 'right'
    },
    {
        id: 5,
        time: '5th',
        title: 'Deployment',
        desc: `When teams develop software, they code and test on a different copy of the software than the one that the users have access to. The software that customers use is called production, while other copies are said to be in the build environment, or testing environment.

Having separate build and production environments ensures that customers can continue to use the software even while it is being changed or upgraded. The deployment phase includes several tasks to move the latest build copy to the production environment, such as packaging, environment configuration, and installation.`
        , class: ''
    },
    {
        id: 6,
        time: '6th',
        title: 'Maintenance',
        desc: `In the maintenance phase, among other tasks, the team fixes bugs, resolves customer issues, and manages software changes. In addition, the team monitors overall system performance, security, and user experience to identify new ways to improve the existing software.`, class: ''
    },



]

export default dataRoadMap;