import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import img from "../../assets/images/layouts/avt-01.png";

Project4.propTypes = {
  data: PropTypes.array,
};

function Project4(props) {
  const { data } = props;

  const [dataBlock] = useState({
    subheading: "Our Speciality",
    heading: "SOME OF OUR RECENT GLOBAL CLIENTS",
  });

  return (
    <section className="nft">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="block-text center">
              <h6 className="sub-heading">
                <span>{dataBlock.subheading}</span>
              </h6>
              <h3 className="heading ">{dataBlock.heading}</h3>
            </div>
          </div>

          {data.map((idx) => (
            <Link
              to={idx?.link}
              target="_blank"
              rel="noopener noreferrer"
              key={idx.id}
              className="col-xl-3 col-md-6"
            >
              <div style={{ marginBottom: "30px" }} className="team-box">
                <div className="image">
                  <Link to="/">
                    <img
                      style={{ backgroundColor: "whitesmoke" }}
                      src={idx.img}
                      alt="Cyfonii"
                    />
                  </Link>
                </div>
                <div className="content">
                  <Link to="/" className="h5 name">
                    {idx.title}
                  </Link>
                  <p style={{ fontFamily: "arial" }} className="postion">
                    {idx.des}
                  </p>
                </div>
              </div>
            </Link>
          ))}
        </div>
        <p style={{ textAlign: "right", marginTop: "30px" }}>
          === To get more projects detail please contact us. ===
        </p>
      </div>
    </section>
  );
}

export default Project4;
