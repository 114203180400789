
import img1 from '../images/new/web.webp'
import img2 from '../images/new/ui-ux.webp'
import img3 from '../images/new/automation.webp'
import img4 from '../images/new/ai.webp'
import img5 from '../images/new/web3.webp'
import img6 from '../images/new/cyber-security.webp'
import img7 from '../images/new/bot.webp'

import avt from '../images/layouts/avt-05.png'



const dataCard = [

    {
        id: 1,
        img: img1,
        title: 'Web & Mobile App Development',
        price: '2.26 ETH',
        avt: avt,
        name: 'Leslie Alexander',
        tag: '@leslie754',
        bg: '#F58EFF'
    },

    {
        id: 2,
        img: img2,
        title: 'UI UX Design',
        price: '2.26 ETH',
        avt: avt,
        name: 'Leslie Alexander',
        tag: '@leslie754'
        ,
        bg: '#FF8E55'
    },
    {
        id: 3,
        img: img3,
        title: 'Automation',
        price: '2.26 ETH',
        avt: avt,
        name: 'Leslie Alexander',
        tag: '@leslie754'
        ,
        bg: '#9FFCFD '
    },
    {
        id: 4,
        img: img4,
        title: 'Artificial Intelligence (Ai)',
        price: '2.26 ETH',
        avt: avt,
        name: 'Leslie Alexander',
        tag: '@leslie754'
        ,
        bg: '#EF88BE'
    },
    {
        id: 5,
        img: img5,
        title: 'Web3/ Blockchain Development ',
        price: '2.26 ETH',
        avt: avt,
        name: 'Leslie Alexander',
        tag: '@leslie754'
        ,
        bg: '#8288FF'
    },
    {
        id: 5,
        img: img6,
        title: 'Cyber Security ',
        price: '2.26 ETH',
        avt: avt,
        name: 'Leslie Alexander',
        tag: '@leslie754'
        ,
        bg: '#BFFFC0'
    },
    {
        id: 5,
        img: img7,
        title: 'Robotics ',
        price: '2.26 ETH',
        avt: avt,
        name: 'Leslie Alexander',
        tag: '@leslie754'
        ,
        bg: '#FFE793'
    },


]

export default dataCard;