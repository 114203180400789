import React from "react";
import PageTitle from "../../components/pagetitle/PageTitle";
import Footer from "../../components/footer/Footer";
import Sidebar from "../../components/sidebar/Sidebar";
import { Link } from "react-router-dom";

import img1 from "../../assets/images/new/fintech.webp";
import avt1 from "../../assets/images/layouts/avt-01.png";
import avt2 from "../../assets/images/layouts/avt-02.png";
import avt3 from "../../assets/images/layouts/avt-03.png";
import avt4 from "../../assets/images/layouts/avt-04.png";
import Footer2 from "../../components/footer/Footer2";

function Finance(props) {
  return (
    <div className="wrapper">
      <section className="page-title">
        <div className="shape"></div>
        <div className="shape right s3"></div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="page-title__body">
                <div className="page-title__main">
                  <h4 style={{ fontSize: "20px" }} className="title">
                    {"Guide to Modern Fintech Software Development"}
                  </h4>

                  {/* <ul className="breacrumb">
                                <li><a href="/">Home</a></li>
                                <li><p>Explore</p></li>
                                <li><p>{title}</p></li>
                            </ul> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bloglist">
        <div className="container">
          <div className="row">
            <div className="bloglist__main">
              <div className="list details">
                <p>
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.9375 0.75V2.0625M9.0625 0.75V2.0625M0.75 9.9375V3.375C0.75 3.0269 0.888281 2.69306 1.13442 2.44692C1.38056 2.20078 1.7144 2.0625 2.0625 2.0625H9.9375C10.2856 2.0625 10.6194 2.20078 10.8656 2.44692C11.1117 2.69306 11.25 3.0269 11.25 3.375V9.9375M0.75 9.9375C0.75 10.2856 0.888281 10.6194 1.13442 10.8656C1.38056 11.1117 1.7144 11.25 2.0625 11.25H9.9375C10.2856 11.25 10.6194 11.1117 10.8656 10.8656C11.1117 10.6194 11.25 10.2856 11.25 9.9375M0.75 9.9375V5.5625C0.75 5.2144 0.888281 4.88056 1.13442 4.63442C1.38056 4.38828 1.7144 4.25 2.0625 4.25H9.9375C10.2856 4.25 10.6194 4.38828 10.8656 4.63442C11.1117 4.88056 11.25 5.2144 11.25 5.5625V9.9375"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  June 18, 2024
                </p>

                <div className="line"></div>

                <div className="content-d">
                  {/* <ul className="list-social">
                    <li>
                      <Link to="#">
                        <svg
                          width="9"
                          height="16"
                          viewBox="0 0 9 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M2.57969 9.03953C2.51969 9.03953 1.19969 9.03953 0.599688 9.03953C0.279688 9.03953 0.179688 8.91953 0.179688 8.61953C0.179688 7.81953 0.179688 6.99953 0.179688 6.19953C0.179688 5.87953 0.299688 5.77953 0.599688 5.77953H2.57969C2.57969 5.71953 2.57969 4.55953 2.57969 4.01953C2.57969 3.21953 2.71969 2.45953 3.11969 1.75953C3.53969 1.03953 4.13969 0.559531 4.89969 0.279531C5.39969 0.0995311 5.89969 0.0195312 6.43969 0.0195312H8.39969C8.67969 0.0195312 8.79969 0.139531 8.79969 0.419531V2.69953C8.79969 2.97953 8.67969 3.09953 8.39969 3.09953C7.85969 3.09953 7.31969 3.09953 6.77969 3.11953C6.23969 3.11953 5.95969 3.37953 5.95969 3.93953C5.93969 4.53953 5.95969 5.11953 5.95969 5.73953H8.27969C8.59969 5.73953 8.71969 5.85953 8.71969 6.17953V8.59953C8.71969 8.91953 8.61969 9.01953 8.27969 9.01953C7.55969 9.01953 6.01969 9.01953 5.95969 9.01953V15.5395C5.95969 15.8795 5.85969 15.9995 5.49969 15.9995C4.65969 15.9995 3.83969 15.9995 2.99969 15.9995C2.69969 15.9995 2.57969 15.8795 2.57969 15.5795C2.57969 13.4795 2.57969 9.09953 2.57969 9.03953Z"
                            fill="white"
                          ></path>
                        </svg>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <svg
                          width="15"
                          height="12"
                          viewBox="0 0 15 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.5 1.42062C13.9794 1.66154 13.4246 1.82123 12.8462 1.89877C13.4412 1.524 13.8954 0.935077 14.1089 0.225231C13.5541 0.574154 12.9416 0.820615 12.2889 0.958154C11.7621 0.366462 11.0114 0 10.1924 0C8.60337 0 7.32412 1.36062 7.32412 3.02862C7.32412 3.26862 7.34338 3.49938 7.39062 3.71908C5.0045 3.59631 2.89313 2.38985 1.47475 0.552C1.22712 1.00523 1.08188 1.524 1.08188 2.08246C1.08188 3.13108 1.59375 4.06062 2.35675 4.59877C1.89562 4.58954 1.44325 4.44831 1.06 4.22585C1.06 4.23508 1.06 4.24708 1.06 4.25908C1.06 5.73046 2.05487 6.95262 3.3595 7.23415C3.12587 7.30154 2.87125 7.33385 2.607 7.33385C2.42325 7.33385 2.23775 7.32277 2.06362 7.28215C2.4355 8.48123 3.49075 9.36277 4.7455 9.39138C3.769 10.1972 2.52912 10.6828 1.18688 10.6828C0.9515 10.6828 0.72575 10.6717 0.5 10.6412C1.77137 11.5062 3.27813 12 4.903 12C10.1845 12 13.072 7.38462 13.072 3.384C13.072 3.25015 13.0676 3.12092 13.0615 2.99262C13.6311 2.56615 14.1097 2.03354 14.5 1.42062Z"
                            fill="white"
                          ></path>
                        </svg>
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.0006 14V8.87249C14.0006 6.35249 13.4581 4.42749 10.5181 4.42749C9.10062 4.42749 8.15563 5.19749 7.77063 5.93249H7.73563V4.65499H4.95312V14H7.85813V9.36249C7.85813 8.13749 8.08563 6.96499 9.59063 6.96499C11.0781 6.96499 11.0956 8.34749 11.0956 9.43249V13.9825H14.0006V14Z"
                            fill="white"
                          ></path>
                          <path
                            d="M0.226562 4.65479H3.13156V13.9998H0.226562V4.65479Z"
                            fill="white"
                          ></path>
                          <path
                            d="M1.68 0C0.7525 0 0 0.7525 0 1.68C0 2.6075 0.7525 3.3775 1.68 3.3775C2.6075 3.3775 3.36 2.6075 3.36 1.68C3.36 0.7525 2.6075 0 1.68 0Z"
                            fill="white"
                          ></path>
                        </svg>
                      </Link>
                    </li>
                  </ul> */}

                  <div className="main">
                    <p style={{ fontSize: "20px" }} className="boild">
                      The financial technology (fintech) sector has witnessed
                      exponential growth over the past decade, transforming how
                      financial services are delivered and consumed. Modern
                      fintech solutions leverage advanced technologies such as
                      artificial intelligence (AI), blockchain, and big data
                      analytics to enhance efficiency, security, and user
                      experience. This guide provides a comprehensive overview
                      of the critical aspects of modern fintech software
                      development, from conceptualization to deployment and
                      maintenance.
                    </p>
                    <div className="image">
                      <img
                        style={{
                          backgroundColor: "#ACEDFF",
                          width: "100%",
                          height: "100%",
                        }}
                        src={img1}
                        alt="cyfonii"
                      />
                      <p>Fintech system image</p>
                    </div>
                    <p style={{ fontSize: "18px" }} className="mb-15">
                      <span style={{ fontWeight: "bold" }}>
                        {" "}
                        1. Understanding the Modern Fintech Landscape{" "}
                      </span>
                      <br />
                      <br />
                      Modern fintech encompasses a wide range of applications
                      and services, including:
                      <br />
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        Digital Payments:{" "}
                      </span>{" "}
                      Mobile wallets, contactless payments, and cryptocurrency
                      transactions.
                      <br />
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        Neobanks:{" "}
                      </span>{" "}
                      Digital-only banks offering comprehensive banking services
                      without physical branches.
                      <br />
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        {" "}
                        Lending Platforms:{" "}
                      </span>{" "}
                      Automated loan processing and peer-to-peer lending
                      solutions.
                      <br />
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        {" "}
                        Investment Services:{" "}
                      </span>{" "}
                      Robo-advisors, digital trading platforms, and
                      cryptocurrency exchanges.
                      <br />
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        {" "}
                        Insurtech:{" "}
                      </span>{" "}
                      Technology-driven insurance services offering personalized
                      and streamlined experiences.
                      <br />
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        {" "}
                        Regtech:{" "}
                      </span>{" "}
                      Solutions focused on regulatory compliance and risk
                      management.
                    </p>
                    <br />
                    <p style={{ fontSize: "18px" }} className="mb-15">
                      <span style={{ fontWeight: "bold" }}>
                        2. Defining Scope and Objectives
                      </span>{" "}
                      <br />
                      <br />A clear definition of scope and objectives sets the
                      foundation for successful fintech software development:{" "}
                      <br />
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        Target Audience:
                      </span>{" "}
                      Identify the needs, preferences, and pain points of your
                      target users. <br />
                      <span style={{ fontWeight: "bold" }}>
                        Market Analysis:
                      </span>{" "}
                      Conduct a thorough analysis of market trends, competitor
                      offerings, and regulatory environment.
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        Feature Set:
                      </span>{" "}
                      Define the core features and functionalities that will
                      differentiate your product.
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        Technology Stack:
                      </span>{" "}
                      Select a technology stack that supports scalability,
                      security, and performance.
                    </p>
                    <br />
                    <p style={{ fontSize: "18px" }} className="mb-15">
                      <span style={{ fontWeight: "bold" }}>
                        3. Regulatory Compliance and Security
                      </span>{" "}
                      <br />
                      <br />
                      Compliance with regulatory standards and ensuring security
                      are paramount in fintech development:
                      <br />
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        Data Protection Regulations:
                      </span>{" "}
                      GDPR (EU), CCPA (California), and other local regulations
                      mandate data privacy and protection.
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        Financial Regulations:
                      </span>{" "}
                      PSD2 (EU), Dodd-Frank Act (US), and other regulations
                      ensure transparency and security in financial
                      transactions.
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        KYC/AML Compliance:
                      </span>{" "}
                      Implement robust Know Your Customer (KYC) and Anti-Money
                      Laundering (AML) procedures to prevent fraud.
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        Security Standards:
                      </span>{" "}
                      Adhere to PCI-DSS for payment processing and employ strong
                      encryption, multi-factor authentication (MFA), and regular
                      security audits.
                    </p>
                    <br />
                    <p style={{ fontSize: "18px" }} className="mb-15">
                      <span style={{ fontWeight: "bold" }}>
                        4. Leveraging Advanced Technologies
                      </span>{" "}
                      <br />
                      <br />
                      Modern fintech solutions are powered by advanced
                      technologies that drive innovation and efficiency:
                      <br />
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        Artificial Intelligence and Machine Learning:
                      </span>{" "}
                      Use AI/ML for fraud detection, risk assessment,
                      personalized financial advice, and chatbots.
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        Blockchain:
                      </span>{" "}
                      Implement blockchain for secure, transparent, and
                      tamper-proof transactions, smart contracts, and digital
                      identity verification.
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        Big Data Analytics:
                      </span>{" "}
                      Analyze vast amounts of data to derive insights, enhance
                      customer experiences, and optimize decision-making
                      processes.
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        APIs and Microservices:
                      </span>{" "}
                      Use APIs for seamless integration with third-party
                      services and microservices architecture for scalable and
                      flexible development.
                    </p>
                    <br />
                    <p style={{ fontSize: "18px" }} className="mb-15">
                      <span style={{ fontWeight: "bold" }}>
                        5. User Experience and Interface Design
                      </span>{" "}
                      <br />
                      <br />
                      A superior user experience (UX) and intuitive user
                      interface (UI) are critical for customer satisfaction and
                      retention:
                      <br />
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        Responsive Design:
                      </span>{" "}
                      Ensure the application is accessible and functional across
                      various devices and screen sizes.
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        Intuitive Navigation:
                      </span>{" "}
                      Design user-friendly interfaces that simplify complex
                      financial tasks.
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        Personalization:
                      </span>{" "}
                      Leverage user data to offer personalized experiences and
                      recommendations.
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        Accessibility:
                      </span>{" "}
                      Ensure the application is accessible to users with
                      disabilities by adhering to WCAG (Web Content
                      Accessibility Guidelines).
                    </p>
                    <br />
                    <p style={{ fontSize: "18px" }} className="mb-15">
                      <span style={{ fontWeight: "bold" }}>
                        {" "}
                        6. Development Methodologies and Practices
                      </span>{" "}
                      <br /> <br /> Adopting modern development methodologies
                      enhances efficiency and collaboration:
                      <br />
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        Agile Methodology:
                      </span>{" "}
                      Promote iterative development, continuous feedback, and
                      flexibility to adapt to changes.
                      <br />
                      <span style={{ fontWeight: "bold" }}>DevOps:</span>{" "}
                      Integrate development and operations to streamline
                      deployment, CI/CD (Continuous Integration/Continuous
                      Deployment), and monitoring.
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        Test-Driven Development (TDD):
                      </span>{" "}
                      Write tests before code to ensure functionality and reduce
                      bugs.
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        Version Control:
                      </span>{" "}
                      Use version control systems like Git for efficient
                      collaboration and code management.
                    </p>
                    <br />
                    <p style={{ fontSize: "18px" }} className="mb-15">
                      <span style={{ fontWeight: "bold" }}>
                        7. Comprehensive Testing and Quality Assurance
                      </span>{" "}
                      <br />
                      <br />
                      Thorough testing ensures the reliability, security, and
                      performance of fintech applications:
                      <br />
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        Unit Testing:
                      </span>{" "}
                      Validate individual components for correct functionality.
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        Integration Testing:
                      </span>{" "}
                      Ensure seamless interaction between different modules.
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        Performance Testing:
                      </span>{" "}
                      Assess the application’s performance under various
                      conditions and loads.
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        Security Testing:
                      </span>{" "}
                      Identify and mitigate security vulnerabilities through
                      penetration testing and regular audits.
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        User Acceptance Testing (UAT):
                      </span>{" "}
                      Validate the application with real users to ensure it
                      meets their needs and expectations.
                    </p>
                    <br />
                    <p style={{ fontSize: "18px" }}>
                      <span style={{ fontWeight: "bold" }}>
                        8. Deployment, Monitoring, and Maintenance
                      </span>{" "}
                      <br />
                      <br /> Effective deployment, continuous monitoring, and
                      proactive maintenance are essential for long-term success:
                      <br />
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        Deployment Strategy:
                      </span>{" "}
                      Choose between cloud-based or on-premises deployment based
                      on scalability and security needs.
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        Monitoring Tools:
                      </span>{" "}
                      Implement tools for real-time monitoring of application
                      performance, user activity, and security threats.
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        Regular Updates:
                      </span>{" "}
                      Continuously update the application to fix bugs, enhance
                      security, and add new features.
                      <br />
                      <span style={{ fontWeight: "bold" }}>
                        Customer Support:
                      </span>{" "}
                      Provide robust customer support to address user issues,
                      gather feedback, and improve the product.
                    </p>
                    <br />
                    <p style={{ fontSize: "18px" }}>
                      <span style={{ fontWeight: "bold" }}>Conclusion</span>{" "}
                      <br />
                      Modern fintech software development is a dynamic and
                      multifaceted process that demands a thorough understanding
                      of the financial landscape, adherence to regulatory
                      standards, and the integration of cutting-edge
                      technologies. By focusing on security, user experience,
                      and continuous improvement, developers can create
                      innovative fintech solutions that drive financial
                      inclusion, enhance user satisfaction, and achieve
                      long-term success.
                    </p>
                    <div className="line"></div>
                    <div className="bottom">
                      <p>Tags:</p>
                      <ul className="list-tag">
                        <li>
                          <Link to="/blog-details">nfts</Link>
                        </li>
                        <li>
                          <Link to="/blog-details">crypto</Link>
                        </li>
                        <li>
                          <Link to="/blog-details">cyfonii</Link>
                        </li>
                        <li>
                          <Link to="/blog-details">collection</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="bottom">
                  <div className="recent">
                    <div className="ac prev">
                      <svg
                        width="20"
                        height="36"
                        viewBox="0 0 10 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.75 16.5L1.25 9L8.75 1.5"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>

                      <div>
                        <div className="meta ">
                          <Link to="/blog/ai-startup">
                            <svg
                              width="12"
                              height="12"
                              viewBox="0 0 12 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.9375 0.75V2.0625M9.0625 0.75V2.0625M0.75 9.9375V3.375C0.75 3.0269 0.888281 2.69306 1.13442 2.44692C1.38056 2.20078 1.7144 2.0625 2.0625 2.0625H9.9375C10.2856 2.0625 10.6194 2.20078 10.8656 2.44692C11.1117 2.69306 11.25 3.0269 11.25 3.375V9.9375M0.75 9.9375C0.75 10.2856 0.888281 10.6194 1.13442 10.8656C1.38056 11.1117 1.7144 11.25 2.0625 11.25H9.9375C10.2856 11.25 10.6194 11.1117 10.8656 10.8656C11.1117 10.6194 11.25 10.2856 11.25 9.9375M0.75 9.9375V5.5625C0.75 5.2144 0.888281 4.88056 1.13442 4.63442C1.38056 4.38828 1.7144 4.25 2.0625 4.25H9.9375C10.2856 4.25 10.6194 4.38828 10.8656 4.63442C11.1117 4.88056 11.25 5.2144 11.25 5.5625V9.9375"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            April 11, 2024
                          </Link>
                        </div>
                        <Link to="/blog/ai-startup" className="title">
                          Start An Ai Start-up
                        </Link>
                      </div>
                    </div>
                    <div className="ac next">
                      <svg
                        width="20"
                        height="36"
                        viewBox="0 0 10 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.25 16.5L8.75 9L1.25 1.5"
                          stroke="white"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>

                      <div>
                        <div className="meta ">
                          <Link to="/blog/cyber-security">
                            <svg
                              width="12"
                              height="12"
                              viewBox="0 0 12 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M2.9375 0.75V2.0625M9.0625 0.75V2.0625M0.75 9.9375V3.375C0.75 3.0269 0.888281 2.69306 1.13442 2.44692C1.38056 2.20078 1.7144 2.0625 2.0625 2.0625H9.9375C10.2856 2.0625 10.6194 2.20078 10.8656 2.44692C11.1117 2.69306 11.25 3.0269 11.25 3.375V9.9375M0.75 9.9375C0.75 10.2856 0.888281 10.6194 1.13442 10.8656C1.38056 11.1117 1.7144 11.25 2.0625 11.25H9.9375C10.2856 11.25 10.6194 11.1117 10.8656 10.8656C11.1117 10.6194 11.25 10.2856 11.25 9.9375M0.75 9.9375V5.5625C0.75 5.2144 0.888281 4.88056 1.13442 4.63442C1.38056 4.38828 1.7144 4.25 2.0625 4.25H9.9375C10.2856 4.25 10.6194 4.38828 10.8656 4.63442C11.1117 4.88056 11.25 5.2144 11.25 5.5625V9.9375"
                                stroke="white"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            February 11, 2023
                          </Link>
                        </div>
                        <Link to="/blog/cyber-security" className="title">
                          Understanding Cyber Security: A Comprehensive Guide
                        </Link>
                      </div>
                    </div>
                  </div>

                  {/* <div className="comment">
                    <h5>Comment (4)</h5>

                    <div className="comment-box s1">
                      <div className="avt">
                        <img src={avt1} alt="cyfonii" />
                      </div>
                      <div className="info">
                        <h6 className="name">Annette Black</h6>
                        <p className="meta">February 18, 2022</p>
                        <p className="text">
                          Nullam ornare a magna quis aliquet. Duis suscipit eros
                          in suscipit venenatis. Pellentesque quis efficitur
                          leo. Maecenas accumsan est in nibh interdum, quis
                          dignissim neque scelerisque.
                        </p>
                        <Link to="#">Reply</Link>
                      </div>
                    </div>
                    <div className="comment-box rep">
                      <div className="avt">
                        <img src={avt2} alt="cyfonii" />
                      </div>
                      <div className="info">
                        <h6 className="name">Darlene Robertson</h6>
                        <p className="meta">February 18, 2022</p>
                        <p className="text">
                          Nullam ornare a magna quis aliquet. Duis suscipit eros
                          in suscipit venenatis. Pellentesque quis efficitur
                          leo. Maecenas accumsan est in nibh interdum, quis
                          dignissim neque scelerisque.
                        </p>
                        <Link to="#">Reply</Link>
                      </div>
                    </div>
                    <div className="comment-box">
                      <div className="avt">
                        <img src={avt3} alt="cyfonii" />
                      </div>
                      <div className="info">
                        <h6 className="name">Ronald Richards</h6>
                        <p className="meta">February 18, 2022</p>
                        <p className="text">
                          Nullam ornare a magna quis aliquet. Duis suscipit eros
                          in suscipit venenatis. Pellentesque quis efficitur
                          leo. Maecenas accumsan est in nibh interdum, quis
                          dignissim neque scelerisque.
                        </p>
                        <Link to="#">Reply</Link>
                      </div>
                    </div>
                    <div className="comment-box">
                      <div className="avt">
                        <img src={avt4} alt="cyfonii" />
                      </div>
                      <div className="info">
                        <h6 className="name">Theresa Webb</h6>
                        <p className="meta">February 18, 2022</p>
                        <p className="text">
                          Nullam ornare a magna quis aliquet. Duis suscipit eros
                          in suscipit venenatis. Pellentesque quis efficitur
                          leo. Maecenas accumsan est in nibh interdum, quis
                          dignissim neque scelerisque.
                        </p>
                        <Link to="#">Reply</Link>
                      </div>
                    </div>
                  </div>

                  <form action="#" className="form-box">
                    <div className="row">
                      <div className="col">
                        <h5 className="mb-14">Leave your thought here</h5>
                        <p>
                          Your email address will not be published. Required
                          fields are marked *
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <label>Your name</label>
                        <input type="text" className="form-control" />
                      </div>
                      <div className="col">
                        <label>Your email</label>
                        <input type="email" className="form-control" />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col">
                        <label>Your comment</label>
                        <textarea
                          name="comment"
                          id="comment"
                          cols="30"
                          rows="10"
                        ></textarea>
                      </div>
                    </div>
                    <div className="row mb-0">
                      <div className="col">
                        <button className="action-btn">
                          <span>Submit</span>
                        </button>
                      </div>
                    </div>
                  </form> */}
                </div>
              </div>

              <Sidebar />
            </div>
          </div>
        </div>
      </section>

      <Footer2 />
    </div>
  );
}

export default Finance;
